.statusBooked{
color:#264653
}
.statusProcess{
color:#2a9d8f
}
.statusForwarded{
color:#e9c46a;
}
.statusTransit{
color:#f4a261;
}
.statusCustomClearance{
    color:#e76f51;
}
.statusOutForDelivery{
color:#918450;
}
.statusDelivered{
color:#7e1f86;
}
.statusCanceled{
color:#f94144;
}

/* old colors */

.undelivered {
    color: #9ea7ad;
}

.hold {
    color: #2dccff;

}

.cancelled {
    color: #ff3838;

}

.custom-clearance {
    color: #ffb302;

}

.out-for-delivery {
    color: #fce83a;
}

.delivered {
    color: #56f000;
    

}
